/* eslint-disable no-duplicate-case */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SafeRoute from "./auth/SafeRoute";
import EthSignupPage from "./operators/pages/EthSignupPage/EthSignupPage";
import EthVerifyPage from "./operators/pages/EthVerifyPage/EthVerifyPage";
import NcellSignupPage from "./operators/pages/NcellSignupPage/NcellSignupPage";
import VivaSignupPage from "./operators/pages/OmanSignupPage/SignupPage";
import JawwalSignupPage from "./operators/pages/JawwalSignupPage/SignupPage";
import UnitelSignupPage from "./operators/pages/UnitelSignupPage/SignupPage";
import GamesroidSignupPage from "./operators/pages/GamesroidSignupPage/SignupPage";
import RamdankGamesroidSignupPage from "./operators/pages/RamdankGamesroidSignupPage/SignupPage";
import KsaSignupPage from "./operators/pages/KsaSignupPage/SignupPage";
import platItFreeSignupPage from "./operators/pages/playItfreeSignup/SignupPage";
import NigeriaSignupPage from "./operators/pages/NigeriaSignupPage/SignupPage";
import JawwalgamezroidSignupPage from "./operators/pages/JawwalgamezroidSignupPage/SignupPage";
import BatelcoBhMdSignupPage from "./operators/pages/BatelcoBhMdSignupPage/SignupPage";
import OoredoopsSignupPage from "./operators/pages/OoredoopsSignupPage/SignupPage";
import ZainiqSignupPage from "./operators/pages/ZainiqSignupPage/SignupPage";
import ZainiqGamezoneSignupPage from "./operators/pages/ZainiqGamezoneSignupPage/SignupPage";
import LibyanaSignupPage from "./operators/pages/LibyanaSignupPage/SignupPage";
import OaTrendySignupPage from "./operators/pages/OaTrendySignupPage/SignupPage";
import NewOaTrendySignupPage from "./operators/pages/NewOaTrendySignupPage/SignupPage";
import AsianSignupPage from "./operators/pages/AsianSignupPage/SignupPage";
import TrueMoveSignupPage from "./operators/pages/TrueMoveSignupPage/SignupPage";

import Ksa3AnetSignupPage from "./operators/pages/Ksa3AnetSignupPage/SignupPage";

import MauritaniaSignupPage from "./operators/pages/MauritaniaSignupPage/SignupPage";

import KorekiqSignupPage from "./operators/pages/korekiqSignupPage/SignupPage";
import KorekTrendySignupPage from "./operators/pages/korekTrendySignupPage/SignupPage";
import KoreqSignupPage from "./operators/pages/koreqSignupPage/SignupPage";
import ZASignupPage from "./operators/pages/ZASignupPage/SignupPage";

import KorekTrendyPinPage from "./operators/pages/korekTrendyPinPage/SignupPage";
import KoreqPinPage from "./operators/pages/koreqPinPage/SignupPage";

import LibyanaMarketingSignupPage from "./operators/pages/LibyanaMarketingSignupPage/SignupPage";
import LibyanaMarketingSignupPage2 from "./operators/pages/LibyanaMarketing2/LibyanaMarketing2";
import LibyanaMarketingThree from "./operators/pages/LibyanaMarketingThree/LibyanaMarketingThree";

import UmniahSignupPage from "./operators/pages/UmniahSignupPage/SignupPage";
// import DuWifiSignupPage from "./operators/pages/duWifiSignupPage/SignupPage";
import YmenSignupPage from "./operators/pages/YmenSignupPage/SignupPage";
import asiacellSignupPage from "./operators/pages/asiacellSignupPage/SignupPage";
import {
  DuverifyPage,
  EgyVerifyPage,
  IndonesiaVerifyPage,
  BahrainVerifyPage,
} from "./pages";

import SharedUnsubscribe from "./operators/pages/SharedUnsubscribe/SharedUnsubscribe";
import {
  FAQPage,
  FavouritesPage,
  ForgetPasswordPage,
  GameDetailsPage,
  GamePage,
  EgyCatchPage,
  HomePage,
  HomePageCompetition,
  LanguagePage,
  LoginPage,
  ZainCatchPage,
  ZainGamezroidCatchPage,
  lebianaCatchPage,
  ZACatchPage,
  ZACatchLoginPage,
  trendyCatch,
  ksa3anetCatch,
  ZainGamezoneCatchPage,
  KorikCatchPage,
  LogoutPage,
  NotFoundPage,
  PaymentPage,
  ProfilePage,
  packagesPage,
  FreePointPage,
  WinnersPage,
  GamezroidcomProfilePage,
  RankingPage,
  RankingPagCompetition,
  GamezroidcomRankingPage,
  SignupPage,
  NewAsiacellSubscribePage,
  SubscribeCompletePage,
  SubscribeCompleteKoreqPage,
  TermsPage,
  privacypolicyPage,
  helpPage,
  Rules,
  AdsPage,
  UnsubscribePage,
  VerifyPage,
  VoucherPage,
  KsaCatchPage,
  asiacellCatchPage,
  EgyLoginPage,
  BahrainCatchPage,
  OmantelCatchPage,
  UmniahCatchPage,
  StckuwaitCatchPage,
  asiacellThankYouPage,
  ThankYouPage,
  KsaThankYouPage,
  gamezoneThankYouPage,
  OoredooLoginPage,
  EthioLoginPage,
  EgyMondiaPayLoginPage,
  OaTrendyLoginPage,
  AlgeriaorLoginPage,
  DuLoginPage,
  BahrainLoginPage,
  GamezroidComLoginPage,
  MondiaSubscribePage,
  DuSubscribePage,
  ZainBHSubscribePage,
  NewEgySubscribePage,
  EgyptSubscribePage,
  OmantelSignUpPage,
  OmantelLoginPage,
  OmantelSuccessPage,
  IndonesiaSubscribePage,
  YemenMobileSubscribePage,
  SabafonSubscribePage,
  NewNigeriaSubscribePage,
  MondiaVerifyPage,
  MondiaCatchPage,
  EgyCatchFawryPage,
} from "./pages";
import BrainGameDetailsPage from "./pages/BrainGames/Components/GameDetailsPage/BrainGameDetailsPage";
import VideosPage from "./pages/VideosPage";
import ValentinePage from "./pages/ValentinePage";
import WomenDayPage from "./pages/WomenDayPage";
import WheelPage from "./pages/WheelPage";
import ShortUrlLoginPage from "./pages/ShortUrlLoginPage/ShortUrlLoginPage";
import Search from "./components/Search/Search";
import BrainSearch from './pages/BrainGames/Components/Search/Search'
import VivaPageeDisplay from "./pages/VivaPage/VoucherPage.styled";
//import VivaSignupPage from "./operators/pages/VivaSignupPage/VivaSignupPage";
import GameFreamePage from "./pages/GameDetailsPage/GameFreamePage";
import NewZainSubscribePage from "./pages/NewZainSubscribePage/NewZainSubscribePage";
import { signup } from "./pages/NewAsiacellSubscribePage/services";
import BrainGames from "./pages/BrainGames/BrainGames";
import BrainLoginPage from "./pages/BrainGames/Components/LoginPage/LoginPage";
import BrainSignupPage from "./pages/BrainGames/Components/SignupPage/SignupPage";
import BrainLanguagePage from "./pages/BrainGames/Components/LanguagePage/LanguagePage";
import ActionSignupPage from "./pages/ActionGames/Components/SignupPage/SignupPage";
import ActionLoginPage from "./pages/ActionGames/Components/LoginPage/LoginPage";
import ActionGames from "./pages/ActionGames/ActionGames";
import ActionLanguagePage from "./pages/ActionGames/Components/LanguagePage/LanguagePage";
import ActionGameDetailsPage from "./pages/ActionGames/Components/GameDetailsPage/ActionGameDetailsPage";
// import KsaThankYouPage from "./pages/KsaThankyou/KsaThankYouPage";
// import { signup } from "./operators/pages/EthSignupPage/services";

function redirect() {
  return (window.location.href = "http://zainiq.playit.mobi/");
}

// function redirect2() {
//     return window.location.href = "http://jawwal2.playit.mobi/"
// }
/* locked routing to subscripe in zainiq port*/
function unSubcripeRedirect() {
  if (window.location.href.includes("zainiq.playit")) {
    return false;
  }
  if (window.location.href.includes("jawwal")) {
    return false;
  }
}

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <SafeRoute locked={true} path="/logout" component={LogoutPage} />{" "}
          <SafeRoute
            locked={true}
            path="/brain-games/logout"
            hasNav={false}
            isNav="brain-games"
            component={LogoutPage}
          />{" "}
          <SafeRoute
            locked={true}
            hasNav={false}
            isNav="action-games"
            path="/action-games/logout"
            component={LogoutPage}
          />{" "}
          <SafeRoute
            locked={true}
            hasNav={true}
            path="/my-games"
            component={FavouritesPage}
          />{" "}
          <SafeRoute
            locked={true}
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/my-games"
            component={FavouritesPage}
          />{" "}
          <SafeRoute
            locked={true}
            hasNav={false}
            isNav="action-games"
            path="/action-games/my-games"
            component={FavouritesPage}
          />{" "}
          {/* <SafeRoute locked={true} path="/profile" component={ProfilePage} /> */}{" "}
          <SafeRoute
            locked={true}
            path="/profile"
            component={getHostComponentByRoute("profile")}
          />
          <SafeRoute
            locked={true}
            hasNav={false}
            isNav="action-games"
            path="/action-games/profile"
            component={getHostComponentByRoute("profile")}
          />
          <SafeRoute
            locked={true}
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/profile"
            component={getHostComponentByRoute("profile")}
          />
          <SafeRoute
            hasNav={true && unSubcripeRedirect()}
            locked={true}
            path="/unsubscribe"
            component={getHostComponentByRoute("unsubscribe")}
          />
          <SafeRoute
            hasNav={false}
            locked={true}
            isNav="action-games"
            path="/action-games/unsubscribe"
            component={getHostComponentByRoute("unsubscribe")}
          />
          <SafeRoute
            hasNav={false}
            locked={true}
            isNav="brain-games"
            path="/brain-games/unsubscribe"
            component={getHostComponentByRoute("unsubscribe")}
          />
          <SafeRoute
            locked={true}
            path="/add-voucher"
            component={VoucherPage}
          />{" "}
          {/* <SafeRoute exact path="/" component={HomePage} /> */}{" "}
          {/* <SafeRoute exact path="/home" component={HomePage} /> */}{" "}
          <SafeRoute exact path="/home" component={HomePage} />
          <SafeRoute
            exact
            hasNav={false}
            isNav="brain-games"
            path="/brain-games"
            component={BrainGames}
          />
          <SafeRoute
            exact
            hasNav={false}
            isNav="action-games"
            path="/action-games"
            component={ActionGames}
          />
          <SafeRoute path="/slogin/:id" component={ShortUrlLoginPage} />{" "}
          <SafeRoute path="/viva" component={VivaPageeDisplay} />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-zainiq"
            component={ZainCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-zainiqgamezroid"
            component={ZainGamezroidCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-zainiqgamezone"
            component={ZainGamezoneCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/success"
            component={OmantelSuccessPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-Libyana"
            component={lebianaCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-ZA"
            component={ZACatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-login-ZA"
            component={ZACatchLoginPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-trendy"
            component={trendyCatch}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-ksa3anet"
            component={ksa3anetCatch}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-korikPlayIT"
            component={KorikCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-ksa"
            component={KsaCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/login/catch-ksa"
            component={KsaCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-asiacell"
            component={asiacellCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/thankyou"
            component={ThankYouPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/thankyouksa"
            component={KsaThankYouPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/thank-ksa"
            component={ThankYouPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/thank-gamezone"
            component={gamezoneThankYouPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/thank-asiacell"
            component={asiacellThankYouPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-egy"
            component={EgyCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-ncell"
            component={EgyCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/eth_terms"
            component={EthVerifyPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/catch-stckuwait"
            component={StckuwaitCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/bahrain-catch"
            component={BahrainCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/omantel-catch"
            component={OmantelCatchPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            path="/umniah-catch"
            component={UmniahCatchPage}
          />
          <SafeRoute
            exact
            hasNav={false}
            path="/egy-login"
            component={EgyLoginPage}
          />{" "}
          {/* <SafeRoute exact hasNav={false} path="/login" component={LoginPage} /> */}
          <SafeRoute exact path="/packages" component={packagesPage} />{" "}
          <SafeRoute exact path="/invite" component={FreePointPage} />
          <SafeRoute exact path="/winners" component={WinnersPage} />
          <SafeRoute exact path="/" component={getHostComponentByRoute("/")} />
          <SafeRoute
            hasNav={false}
            path="/login"
            component={getHostComponentByRoute("login")}
          />
          <SafeRoute
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/login"
            component={getHostComponentByRoute("brain-games/login")}
          />
          <SafeRoute
            path="/action-games/login"
            hasNav={false}
            isNav="action-games"
            component={getHostComponentByRoute("action-games/login")}
          />
          <SafeRoute
            hasNav={false}
            path="/signup"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/signup"
            component={getHostComponentByRoute("brain-signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            isNav="action-games"
            path="/action-games/signup"
            component={getHostComponentByRoute("action-signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup11"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup21"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup31"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup41"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup51"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup61"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup71"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signupksa"
            component={getHostComponentByRoute("signup")}
          />
          <SafeRoute
            hasNav={false}
            path="/signup1"
            component={getHostComponentByRoute("signup")}
          />
          <SafeRoute
            hasNav={false}
            path="/signup2"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup3"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup4"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup5"
            component={getHostComponentByRoute("signup")}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/signup6"
            component={getHostComponentByRoute("signup")}
          />
          <SafeRoute
            hasNav={false}
            path="/Ramdanksignup"
            component={getHostComponentByRoute("signup")}
          />
          <SafeRoute
            hasNav={false}
            path="/complete"
            component={SubscribeCompletePage}
          />
          <SafeRoute
            hasNav={false}
            path="/complete_koreq"
            component={SubscribeCompleteKoreqPage}
          />{" "}
          <Route hasNav={false} path="/verify" component={VerifyPage} />
          <Route hasNav={false} path="/fail" component={KorekTrendyPinPage} />
          <Route hasNav={false} path="/fail_koreq" component={KoreqPinPage} />
          <Route
            hasNav={false}
            path="/marketing"
            component={LibyanaMarketingSignupPage}
          />
          <Route
            hasNav={false}
            path="/marketing3"
            component={LibyanaMarketingSignupPage2}
          />
          <Route
            hasNav={false}
            path="/marketing2"
            component={LibyanaMarketingThree}
          />
          <SafeRoute path="/change-language" component={LanguagePage} />{" "}
          <SafeRoute
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/change-language"
            component={BrainLanguagePage}
          />{" "}
          <SafeRoute
            hasNav={false}
            isNav="action-games"
            path="/action-games/change-language"
            component={ActionLanguagePage}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/mondiasubscribe"
            component={MondiaSubscribePage}
          />
          <SafeRoute
            hasNav={true}
            path="/mondiaverify"
            component={MondiaVerifyPage}
          />
          <SafeRoute hasNav={true} path="/duverify" component={DuverifyPage} />
          <SafeRoute
            hasNav={true}
            path="/egyverify"
            component={EgyVerifyPage}
          />
          <SafeRoute
            hasNav={true}
            path="/bahrainVerify"
            component={BahrainVerifyPage}
          />
          <SafeRoute
            hasNav={true}
            path="/indonesiaverify"
            component={IndonesiaVerifyPage}
          />
          <SafeRoute
            hasNav={true}
            path="/egycatchfawry/:id"
            component={EgyCatchFawryPage}
          />
          <SafeRoute
            hasNav={false}
            path="/mondiacatch"
            component={MondiaCatchPage}
          />
          <SafeRoute
            hasNav={false}
            path="/ducatch"
            component={MondiaCatchPage}
          />
          <SafeRoute
            hasNav={false}
            path="/forget-password"
            component={ForgetPasswordPage}
          />
          <SafeRoute
            path="/leaderboard"
            component={getHostComponentByRoute("leaderboard")}
          />
          <SafeRoute
            hasNav={false}
            path="/valentine-pastime"
            component={ValentinePage}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/women-day"
            component={WomenDayPage}
          />{" "}
          <SafeRoute path="/Wheel" component={WheelPage} />{" "}
          <SafeRoute path="/videoPlay" component={VideosPage} />{" "}
          <SafeRoute path="/faqs" component={FAQPage} />{" "}
          <SafeRoute path="/gamex/:gameID" component={GamePage} />{" "}
          <SafeRoute path="/game/:gameID" component={GameDetailsPage} />{" "}
          <SafeRoute
            exact
            hasNav={false}
            isNav="brain-games"
            path="/brain-games/game/:gameID"
            component={BrainGameDetailsPage}
          />{" "}
          <SafeRoute
            exact
            hasNav={false}
            isNav="action-games"
            path="/action-games/game/:gameID"
            component={ActionGameDetailsPage}
          />{" "}
          <SafeRoute
            path="/gamefree/:gameID/:userID"
            component={GameDetailsPage}
          />{" "}
          <SafeRoute
            hasNav={false}
            path="/frame/:gameID"
            component={GameFreamePage}
          />{" "}
          <SafeRoute
            path="/brain-games/frame/:gameID"
            hasNav={false}
            // isNav="brain-games"
            component={GameFreamePage}
          />{" "}
          <SafeRoute
            path="/action-games/frame/:gameID"
            component={GameFreamePage}
          />{" "}
          <SafeRoute exact path="/payment" component={PaymentPage} />{" "}
          <SafeRoute path="/search" component={Search} />{" "}
          <SafeRoute
            path="/action-games/search"
            hasNav={false}
            isNav="action-games"
            component={Search}
          />{" "}
          <SafeRoute
            path="/brain-games/search"
            hasNav={false}
            isNav="brain-games"
            component={BrainSearch}
          />{" "}
          <SafeRoute path="/tos" component={TermsPage} />{" "}
          <SafeRoute path="/privacypolicy" component={privacypolicyPage} />{" "}

          <SafeRoute
            path="/action-games/tos"
            hasNav={false}
            isNav="action-games"
            component={TermsPage}
          />{" "}
          <SafeRoute
            path="/brain-games/tos"
            hasNav={false}
            isNav="brain-games"
            component={TermsPage}
          />{" "}
          <SafeRoute path="/help" component={helpPage} />{" "}
          <SafeRoute path="/rules" component={Rules} />{" "}
          <SafeRoute path="/ads" component={AdsPage} />
          <SafeRoute
            path="/bahrainLogin"
            component={BahrainLoginPage}
            hasNav={false}
          />
          <SafeRoute
            path="/egy-subscribe"
            hasNav={false}
            component={EgyptSubscribePage}
          />
          <SafeRoute component={NotFoundPage} />
        </Switch>
      </Router>
    );
  }
}

//console.log(document.location.host)
const getHostComponentByRoute = (route) => {
  const hostname = document.location.host;
  if (route === "signup") {
    switch (hostname) {
      case "ethio.playit.mobi":
        return EthSignupPage;
      case "ncell.playit.mobi":
        return NcellSignupPage;
      // case "omantel.playit.mobi":
      //  return VivaSignupPage;
      case "www.egypt.playit.mobi":
        return EgyptSubscribePage;
      case "egypt.playit.mobi":
        return EgyptSubscribePage;
      case "ooredoooman.playit.mobi":
        return OmantelSignUpPage;
      case "ksa.playit.mobi":
        return KsaSignupPage;
      case "umniah.playit.mobi":
        return UmniahSignupPage;
      case "gamesstore.you-tele.com":
        return YmenSignupPage;
      case "www.gamesstore.you-tele.com":
        return YmenSignupPage;
      case "gamesstore.you.com.ye":
        return YmenSignupPage;
      case "www.gamesstore.you.com.ye":
        return YmenSignupPage;
      case "ksa2.playit.mobi":
        return KsaSignupPage;
      case "zain-iq.playit.mobi": //TODO: FOR TESTING ONLY
        return redirect;
      case "jawwal.playit.mobi": //TODO: FOR TESTING ONLY
        return JawwalSignupPage;
      // case "gamezone.playit.mobi": //TODO: FOR TESTING ONLY
      //   return UnitelSignupPage;
      case "competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "mtnng.playit.mobi":
        return NigeriaSignupPage;
      case "www.mtnng.playit.mobi":
        return NigeriaSignupPage;

      case "newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;
      case "www.newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;

      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "www.ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "www.jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "batelco.playit.mobi":
        return BatelcoBhMdSignupPage;
      case "www.batelco.playit.mobi":
        return BatelcoBhMdSignupPage;

      case "gamezroid.com":
        return GamesroidSignupPage;

      case "ethio.gamezroid.com":
        return GamesroidSignupPage;
      case "www.ethio.gamezroid.com":
        return GamesroidSignupPage;

      case "www.gamezroid.com":
        return GamesroidSignupPage;
      case "ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "www.ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "asiacell.gamezroid.com":
        return asiacellSignupPage;
      case "asiacell.playit.mobi":
        return NewAsiacellSubscribePage;
      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;
      case "ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.zainiq.gamezroid.com":
        return ZainiqSignupPage;
      case "zainiq.gamezroid.com":
        return ZainiqSignupPage;

      case "www.korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korek.gamezones.mobi":
        return KorekTrendySignupPage;
      case "koreq.gamezworld.mobi":
        return KoreqSignupPage;
      case "za.playit.mobi":
        return ZASignupPage;
      case "dzo.playit.mobi":
        return OaTrendySignupPage;
      case "www.dzo.playit.mobi":
        return OaTrendySignupPage;
      case "mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "www.mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "www.telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "www.ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "sabafon.playit.mobi":
        return SabafonSubscribePage;
      case "www.sabafon.playit.mobi":
        return SabafonSubscribePage;

      case "du.playit.mobi":
        return DuSubscribePage;
      case "www.du.playit.mobi":
        return DuSubscribePage;
      case "zainbh.playit.mobi":
        //return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.zainbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "libya.playit.mobi":
        return LibyanaSignupPage;
      case "www.libya.playit.mobi":
        return LibyanaSignupPage;
      case "ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "www.ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "dtac.playit.mobi":
        return TrueMoveSignupPage;
      case "truemove.playit.mobi":
        return TrueMoveSignupPage;
      case "ais.playit.mobi":
        return AsianSignupPage;
      case "dzohe.playit.mobi":
        return NewOaTrendySignupPage;
      default:
        //return UnitelSignupPage;
        //return YemenMobileSubscribePage;
        //return MauritaniaSignupPage;
        //return BatelcoBhMdSignupPage;
        //return NewZainSubscribePage;
        // return EgyptSubscribePage
        // return OmantelSignUpPage;
        //return SabafonSubscribePage;
        //return ZainiqGamezoneSignupPage
        // return LibyanaSignupPage;
        // return OaTrendySignupPage;
        //return NewOaTrendySignupPage;
        // return TrueMoveSignupPage;
        //return  KoreqSignupPage
        //  return ZASignupPage
        return SignupPage;
    }
  } else if (route === "brain-signup") {
    switch (hostname) {
      case "ethio.playit.mobi":
        return EthSignupPage;
      case "ncell.playit.mobi":
        return NcellSignupPage;
      // case "omantel.playit.mobi":
      //  return VivaSignupPage;
      case "www.egypt.playit.mobi":
        return EgyptSubscribePage;
      case "egypt.playit.mobi":
        return EgyptSubscribePage;
      case "ooredoooman.playit.mobi":
        return OmantelSignUpPage;
      case "ksa.playit.mobi":
        return KsaSignupPage;
      case "umniah.playit.mobi":
        return UmniahSignupPage;
      case "gamesstore.you-tele.com":
        return YmenSignupPage;
      case "www.gamesstore.you-tele.com":
        return YmenSignupPage;
      case "gamesstore.you.com.ye":
        return YmenSignupPage;
      case "www.gamesstore.you.com.ye":
        return YmenSignupPage;
      case "ksa2.playit.mobi":
        return KsaSignupPage;
      case "zain-iq.playit.mobi": //TODO: FOR TESTING ONLY
        return redirect;
      case "jawwal.playit.mobi": //TODO: FOR TESTING ONLY
        return JawwalSignupPage;
      // case "gamezone.playit.mobi": //TODO: FOR TESTING ONLY
      //   return UnitelSignupPage;
      case "competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "mtnng.playit.mobi":
        return NigeriaSignupPage;
      case "www.mtnng.playit.mobi":
        return NigeriaSignupPage;

      case "newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;
      case "www.newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;

      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "www.ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "www.jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "batelco.playit.mobi":
        return BatelcoBhMdSignupPage;
      case "www.batelco.playit.mobi":
        return BatelcoBhMdSignupPage;

      case "gamezroid.com":
        return GamesroidSignupPage;

      case "ethio.gamezroid.com":
        return GamesroidSignupPage;
      case "www.ethio.gamezroid.com":
        return GamesroidSignupPage;

      case "www.gamezroid.com":
        return GamesroidSignupPage;
      case "ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "www.ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "asiacell.gamezroid.com":
        return asiacellSignupPage;
      case "asiacell.playit.mobi":
        return NewAsiacellSubscribePage;
      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;
      case "ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.zainiq.gamezroid.com":
        return ZainiqSignupPage;
      case "zainiq.gamezroid.com":
        return ZainiqSignupPage;

      case "www.korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korek.gamezones.mobi":
        return KorekTrendySignupPage;
      case "koreq.gamezworld.mobi":
        return KoreqSignupPage;
      case "za.playit.mobi":
        return ZASignupPage;
      case "dzo.playit.mobi":
        return OaTrendySignupPage;
      case "www.dzo.playit.mobi":
        return OaTrendySignupPage;
      case "mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "www.mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "www.telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "www.ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "sabafon.playit.mobi":
        return SabafonSubscribePage;
      case "www.sabafon.playit.mobi":
        return SabafonSubscribePage;

      case "du.playit.mobi":
        return DuSubscribePage;
      case "www.du.playit.mobi":
        return DuSubscribePage;
      case "zainbh.playit.mobi":
        //return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.zainbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "libya.playit.mobi":
        return LibyanaSignupPage;
      case "www.libya.playit.mobi":
        return LibyanaSignupPage;
      case "ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "www.ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "dtac.playit.mobi":
        return TrueMoveSignupPage;
      case "truemove.playit.mobi":
        return TrueMoveSignupPage;
      case "ais.playit.mobi":
        return AsianSignupPage;
      case "dzohe.playit.mobi":
        return NewOaTrendySignupPage;
      default:
        //return UnitelSignupPage;
        //return YemenMobileSubscribePage;
        //return MauritaniaSignupPage;
        //return BatelcoBhMdSignupPage;
        //return NewZainSubscribePage;
        // return EgyptSubscribePage
        // return OmantelSignUpPage;
        //return SabafonSubscribePage;
        //return ZainiqGamezoneSignupPage
        // return LibyanaSignupPage;
        // return OaTrendySignupPage;
        //return NewOaTrendySignupPage;
        // return TrueMoveSignupPage;
        //return  KoreqSignupPage
        //  return ZASignupPage
        return BrainSignupPage;
    }
  } else if (route === "action-signup") {
    switch (hostname) {
      case "ethio.playit.mobi":
        return EthSignupPage;
      case "ncell.playit.mobi":
        return NcellSignupPage;
      // case "omantel.playit.mobi":
      //  return VivaSignupPage;
      case "www.egypt.playit.mobi":
        return EgyptSubscribePage;
      case "egypt.playit.mobi":
        return EgyptSubscribePage;
      case "ooredoooman.playit.mobi":
        return OmantelSignUpPage;
      case "ksa.playit.mobi":
        return KsaSignupPage;
      case "umniah.playit.mobi":
        return UmniahSignupPage;
      case "gamesstore.you-tele.com":
        return YmenSignupPage;
      case "www.gamesstore.you-tele.com":
        return YmenSignupPage;
      case "gamesstore.you.com.ye":
        return YmenSignupPage;
      case "www.gamesstore.you.com.ye":
        return YmenSignupPage;
      case "ksa2.playit.mobi":
        return KsaSignupPage;
      case "zain-iq.playit.mobi": //TODO: FOR TESTING ONLY
        return redirect;
      case "jawwal.playit.mobi": //TODO: FOR TESTING ONLY
        return JawwalSignupPage;
      // case "gamezone.playit.mobi": //TODO: FOR TESTING ONLY
      //   return UnitelSignupPage;
      case "competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
        return platItFreeSignupPage;
      case "mtnng.playit.mobi":
        return NigeriaSignupPage;
      case "www.mtnng.playit.mobi":
        return NigeriaSignupPage;

      case "newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;
      case "www.newmtnng.playit.mobi":
        return NewNigeriaSubscribePage;

      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "www.ethio.gamezones.mobi":
        return UnitelSignupPage;
      case "jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "www.jawwal.gamezroid.com":
        return JawwalgamezroidSignupPage;
      case "batelco.playit.mobi":
        return BatelcoBhMdSignupPage;
      case "www.batelco.playit.mobi":
        return BatelcoBhMdSignupPage;

      case "gamezroid.com":
        return GamesroidSignupPage;

      case "ethio.gamezroid.com":
        return GamesroidSignupPage;
      case "www.ethio.gamezroid.com":
        return GamesroidSignupPage;

      case "www.gamezroid.com":
        return GamesroidSignupPage;
      case "ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "www.ramdank.gamezroid.com":
        return RamdankGamesroidSignupPage;
      case "asiacell.gamezroid.com":
        return asiacellSignupPage;
      case "asiacell.playit.mobi":
        return NewAsiacellSubscribePage;
      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;
      case "ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.ooredoops.playit.mobi":
        return OoredoopsSignupPage;
      case "www.zainiq.gamezroid.com":
        return ZainiqSignupPage;
      case "zainiq.gamezroid.com":
        return ZainiqSignupPage;

      case "www.korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korekiq.playit.mobi":
        return KorekiqSignupPage;
      case "korek.gamezones.mobi":
        return KorekTrendySignupPage;
      case "koreq.gamezworld.mobi":
        return KoreqSignupPage;
      case "za.playit.mobi":
        return ZASignupPage;
      case "dzo.playit.mobi":
        return OaTrendySignupPage;
      case "www.dzo.playit.mobi":
        return OaTrendySignupPage;
      case "mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "www.mauritania.playit.mobi":
        return MauritaniaSignupPage;

      case "telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "www.telkomsel.playit.mobi":
        return IndonesiaSubscribePage;

      case "ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "www.ymobile.playit.mobi":
        return YemenMobileSubscribePage;

      case "sabafon.playit.mobi":
        return SabafonSubscribePage;
      case "www.sabafon.playit.mobi":
        return SabafonSubscribePage;

      case "du.playit.mobi":
        return DuSubscribePage;
      case "www.du.playit.mobi":
        return DuSubscribePage;
      case "zainbh.playit.mobi":
        //return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.zainbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "www.stcbh.playit.mobi":
        // return ZainBHSubscribePage;
        return NewZainSubscribePage;
      case "gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "www.zainiq.gamezones.mobi":
        return ZainiqGamezoneSignupPage;
      case "libya.playit.mobi":
        return LibyanaSignupPage;
      case "www.libya.playit.mobi":
        return LibyanaSignupPage;
      case "ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "www.ksa.gamezroid.com":
        return Ksa3AnetSignupPage;
      case "dtac.playit.mobi":
        return TrueMoveSignupPage;
      case "truemove.playit.mobi":
        return TrueMoveSignupPage;
      case "ais.playit.mobi":
        return AsianSignupPage;
      case "dzohe.playit.mobi":
        return NewOaTrendySignupPage;
      default:
        //return UnitelSignupPage;
        //return YemenMobileSubscribePage;
        //return MauritaniaSignupPage;
        //return BatelcoBhMdSignupPage;
        //return NewZainSubscribePage;
        // return EgyptSubscribePage
        // return OmantelSignUpPage;
        //return SabafonSubscribePage;
        //return ZainiqGamezoneSignupPage
        // return LibyanaSignupPage;
        // return OaTrendySignupPage;
        //return NewOaTrendySignupPage;
        // return TrueMoveSignupPage;
        //return  KoreqSignupPage
        //  return ZASignupPage
        return ActionSignupPage;
    }
  } else if (route === "unsubscribe") {
    switch (hostname) {
      case "ethio.playit.mobi":
        return SharedUnsubscribe;
      case "ooredoooman.playit.mobi":
        return SharedUnsubscribe;
      case "umniah.playit.mobi":
        return SharedUnsubscribe;
      case "jawwal.playit.mobi":
        return SharedUnsubscribe;
      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return SharedUnsubscribe;
      case "mtnng.playit.mobi":
        return SharedUnsubscribe;
      case "www.mtnng.playit.mobi":
        return SharedUnsubscribe;
      case "jawwal.gamezroid.com":
        return SharedUnsubscribe;
      case "www.jawwal.gamezroid.com":
        return SharedUnsubscribe;
      case "ooredoops.playit.mobi":
        return SharedUnsubscribe;
      case "www.ooredoops.playit.mobi":
        return SharedUnsubscribe;
      case "zainiq.gamezroid.com":
        return SharedUnsubscribe;
      case "www.zainiq.gamezroid.com":
        return SharedUnsubscribe;
      case "asiacell.playit.mobi":
        return SharedUnsubscribe;
      case "www.asiacell.playit.mobi":
        return SharedUnsubscribe;
      case "zainbh.playit.mobi":
        return SharedUnsubscribe;
      case "stcbh.playit.mobi":
        return SharedUnsubscribe;
      case "korek.gamezones.mobi":
        return SharedUnsubscribe;
      case "batelco.playit.mobi":
        return SharedUnsubscribe;

      case "ymobile.playit.mobi":
        return SharedUnsubscribe;

      case "sabafon.playit.mobi":
        return SharedUnsubscribe;
      case "ais.playit.mobi":
        return SharedUnsubscribe;
      default:
        return UnsubscribePage;
      // return SharedUnsubscribe
    }
  } else if (route === "leaderboard") {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "competition.playit.mobi":
        return RankingPagCompetition;

      case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
        return RankingPagCompetition;

      case "ramdank.gamezroid.com":
        return WinnersPage;

      case "www.ramdank.gamezroid.com":
        return WinnersPage;
    }
    return RankingPage;
    // return WinnersPage;
  } else if (route === "login") {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "www.ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      case "www.ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      // case "dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      // case "www.dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      case "du.playit.mobi":
        return DuLoginPage;
      case "www.du.playit.mobi":
        return DuLoginPage;

      case "stcbh.playit.mobi":
        return BahrainLoginPage;
      case "www.stcbh.playit.mobi":
        return BahrainLoginPage;

      case "zainbh.playit.mobi":
        return BahrainLoginPage;
      case "www.zainbh.playit.mobi":
        return BahrainLoginPage;
      case "ooredoooman.playit.mobi":
        return OmantelLoginPage;
      case "egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "www.egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "libya.playit.mobi": //TODO: FOR TESTING ONLY
        return LibyanaSignupPage;

      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;

      /* case "dzo.playit.mobi":
        return OaTrendyLoginPage;
      case "www.dzo.playit.mobi":
        return OaTrendyLoginPage; */
    }
    return LoginPage;
    // return OmantelLoginPage;
  } else if (route === "brain-games/login") {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "www.ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      case "www.ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      // case "dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      // case "www.dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      case "du.playit.mobi":
        return DuLoginPage;
      case "www.du.playit.mobi":
        return DuLoginPage;

      case "stcbh.playit.mobi":
        return BahrainLoginPage;
      case "www.stcbh.playit.mobi":
        return BahrainLoginPage;

      case "zainbh.playit.mobi":
        return BahrainLoginPage;
      case "www.zainbh.playit.mobi":
        return BahrainLoginPage;
      case "ooredoooman.playit.mobi":
        return OmantelLoginPage;
      case "egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "www.egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "libya.playit.mobi": //TODO: FOR TESTING ONLY
        return LibyanaSignupPage;

      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;

      /* case "dzo.playit.mobi":
        return OaTrendyLoginPage;
      case "www.dzo.playit.mobi":
        return OaTrendyLoginPage; */
    }
    return BrainLoginPage;
    // return OmantelLoginPage;
  } else if (route === "action-games/login") {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "www.ooredoops.playit.mobi":
        return OoredooLoginPage;
      case "ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      case "www.ramdank.gamezroid.com":
        return GamezroidComLoginPage;
      // case "dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      // case "www.dzo.playit.mobi":
      //   return AlgeriaorLoginPage;
      case "du.playit.mobi":
        return DuLoginPage;
      case "www.du.playit.mobi":
        return DuLoginPage;

      case "stcbh.playit.mobi":
        return BahrainLoginPage;
      case "www.stcbh.playit.mobi":
        return BahrainLoginPage;

      case "zainbh.playit.mobi":
        return BahrainLoginPage;
      case "www.zainbh.playit.mobi":
        return BahrainLoginPage;
      case "ooredoooman.playit.mobi":
        return OmantelLoginPage;
      case "egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "www.egypt.playit.mobi":
        return EgyMondiaPayLoginPage;
      case "ao.playit.mobi": //TODO: FOR TESTING ONLY
        return UnitelSignupPage;
      case "libya.playit.mobi": //TODO: FOR TESTING ONLY
        return LibyanaSignupPage;

      case "aciq.playit.mobi":
        return NewAsiacellSubscribePage;

      /* case "dzo.playit.mobi":
        return OaTrendyLoginPage;
      case "www.dzo.playit.mobi":
        return OaTrendyLoginPage; */
    }
    return ActionLoginPage;
    // return OmantelLoginPage;
  } else if (
    route === "profile" ||
    route === "action-games/profile" ||
    route === "brain-games/profile"
  ) {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "ramdank.gamezroid.com":
        return GamezroidcomProfilePage;
      case "www.ramdank.gamezroid.com":
        return GamezroidcomProfilePage;
    }
    return ProfilePage;
    // return GamezroidcomProfilePage;
  } else if (route === "/") {
    // eslint-disable-next-line default-case
    switch (hostname) {
      case "ramdank.gamezroid.com":
        return HomePageCompetition;
      case "www.ramdank.gamezroid.com":
        return HomePageCompetition;
    }
    return HomePage;
    // return HomePageCompetition;
  }
};
