import React, { Component } from 'react';
import './privacypolicyPage.scss';

class privacypolicyPage extends Component {



	render() {
		return (
			< >
			<div style={{ color: 'black',paddingInline:'1rem' }}  className='text-start' dir='ltr'>
	<privacypolicyPageDisplay   >

    <h1>Privacy Policy for gamezworld.mobi</h1>
    <p>Effective Date: 15/04/2018</p>

    <h2>1. Introduction</h2>
    <p>Welcome to gamezworld.mobi. We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect and process the following data about you:</p>
    <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, phone number, etc.</li>
        <li><strong>Technical Data:</strong> IP address, browser type, operating system, referring URLs, access times, etc.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website and services.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies, web beacons, and other tracking technologies.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use the information we collect in the following ways:</p>
    <ul>
        <li>To provide, operate, and maintain our website and services.</li>
        <li>To improve, personalize, and expand our website and services.</li>
        <li>To understand and analyze how you use our website and services.</li>
        <li>To develop new products, services, features, and functionality.</li>
        <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</li>
        <li>To process your transactions and manage your orders.</li>
        <li>To send you emails.</li>
        <li>To find and prevent fraud.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>We do not sell, trade, or otherwise transfer your Personally Identifiable Information to outside parties except as described below:</p>
    <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers to perform functions and provide services to us.</li>
        <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
    </ul>

    <h2>5. Security of Your Information</h2>
    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.</p>

    <h2>6. Your Data Protection Rights</h2>
    <p>Depending on your location, you may have the following rights regarding your personal data:</p>
    <ul>
        <li><strong>The right to access</strong> – You have the right to request copies of your personal data.</li>
        <li><strong>The right to rectification</strong> – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
        <li><strong>The right to erasure</strong> – You have the right to request that we erase your personal data, under certain conditions.</li>
        <li><strong>The right to restrict processing</strong> – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
        <li><strong>The right to object to processing</strong> – You have the right to object to our processing of your personal data, under certain conditions.</li>
        <li><strong>The right to data portability</strong> – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>

    <h2>7. Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
	
	<h3>
	gamezworld use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.
	</h3>
	
    <h2>8. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
    <ul>
        <li>Email: support@gamezworld.mobi</li>
        <li>Address: 1700 Valley River Drive, Eugene, OR 97401</li>
    </ul>

    <p>By using our site, you consent to our Privacy Policy.</p>



				</privacypolicyPageDisplay>
			</div>
			
			</>
		)
	}
}
export default(privacypolicyPage)